<template>
  <b-row class="justify-content-center">
    <b-col md="6" xl="6">
      <b-card title="Upload foto lokasi">
        <b-overlay :show="loading" rounded="sm">
          <b-form-group label-for="image" class="text-center input-image">
            <image-uploader
              :preview="true"
              :className="[
                'fileinput',
                'custom-file-input',
                { 'fileinput--loaded': hasImage },
              ]"
              :quality="0.5"
              :maxSize="2048"
              :debug="0"
              :autoRotate="true"
              outputFormat="string"
              @input="setImage"
            >
              <label for="fileInput" slot="upload-label" class="d-block">
                <figure>
                  <feather-icon icon="CameraIcon" size="35" />
                </figure>
                <span class="upload-caption">{{
                  hasImage ? "Replace" : "Click to upload"
                }}</span>
              </label>
            </image-uploader>
          </b-form-group>
          <b-button
            v-if="hasImage == true"
            block
            class="mt-2"
            variant="success"
            @click="onSend"
            >Kirim</b-button
          >
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import ImageUploader from "vue-image-upload-resize";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BOverlay,
    ImageUploader,
  },
  data() {
    return {
      loading: false,
      hasImage: false,
      merchantId: null,
      businessType: null,
      state: {
        image: null,
      },
      form: {
        image: null,
        type: 3,
      },
    };
  },
  created() {
    this.merchantId = this.$store.state.app.merchantId;

    if (this.$store.state.app.merchantId === null) {
      this.merchantId = localStorage.getItem("merchantId", this.merchantId);
    }

    this.businessType = this.$store.state.app.businessType;

    if (this.$store.state.app.businessType === null) {
      this.businessType = localStorage.getItem(
        "businessType",
        this.businessType
      );
    }
  },
  methods: {
    setImage(output) {
      this.hasImage = true;
      this.form.image = output;
    },
    validation() {
      if (this.form.image == null) {
        this.$bvToast.toast("Foto tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });
        return false;
      } else {
        return true;
      }
    },
    onSend() {
      if (this.validation()) {
        console.log(this.merchantId);
        this.loading = true;
        this.$http
          .post("/merchants/" + this.merchantId + "/image/add", this.form)
          .then((res) => {
            this.loading = false;
            this.$bvToast.toast("Berhasil", {
              title: "Berhasil",
              variant: "success",
              solid: true,
              autoHideDelay: 1000,
            });
            this.$store.state.app.merchantId = this.merchantId;
            this.$store.state.app.businessType = this.businessType;
            localStorage.setItem("merchantId", this.merchantId);
            localStorage.setItem("businessType", this.businessType);
            this.$router.push("/merchant/add/terms");
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 1000,
            });
          });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
  },
};
</script>
